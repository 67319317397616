import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { Conversation } from "../../interfaces/conversations.type";
import { BaseMessage } from "../../interfaces/messages.type";
import { ConversationQuery } from "../../hooks/apis/conversations.api";

export interface selectedConversaton {
  did: string;
  participant: string;
  position?: number
  search?: string
}

export interface ConversationState {
  data: Conversation[];
  selectedConversation: selectedConversaton | null;
}

const initialState: ConversationState = {
  data: [],
  selectedConversation: null,
};

export const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    setConversations: (state, action: PayloadAction<{ data: Conversation[], query: ConversationQuery, offset: number }>) => {
      if (action.payload.query.searchQuery && action.payload.query.searchQuery !== "" && action.payload.offset === 0) {
        if (action.payload.data.length > 0) {
          const conversations = state.data.filter((item) => item.did !== action.payload.data[0].did)
          state.data = [...conversations, ...action.payload.data]
        }
        else {
          state.data = []
        }
        return
      }
      action.payload.data.forEach((conversation) => {

        const index = state.data.findIndex(
          (item) =>
            item.participant === conversation.participant &&
            item.did === conversation.did
        );
        conversation.total_unread = Number(conversation.total_unread);
        if (index !== -1) {
          state.data[index] = conversation;
        } else {
          state.data.push(conversation);
        }
      });
    },
    setSelectedConversation: (
      state,
      action: PayloadAction<selectedConversaton | null>
    ) => {
      state.selectedConversation = action.payload;
    },

    updateConversationsByIncomingMessage: (state, action: PayloadAction<BaseMessage>) => {
      const index = state.data.findIndex(
        (item) =>
          item.participant === action.payload.participant &&
          item.did === action.payload.did
      );
      if (index !== -1) {
        state.data[index].last_message = action.payload.body;
        state.data[index].last_message_time = action.payload.timestamp;
        state.data[index].resolved_at = null
        state.data[index].resolved = false
        state.data[index].total_unread =
          action.payload.direction == "inbound"
            ? state.data[index].total_unread + 1
            : state.data[index].total_unread;
      } else {
        state.data.push({
          did: action.payload.did,
          participant: action.payload.participant,
          last_message: action.payload.body,
          last_message_time: action.payload.timestamp,
          total_unread: action.payload.direction == "inbound" ? 1 : 0,
          unread: false,
          assigned_user: null,
          resolved_at: null,
          last_read_timestamp: new Date().toISOString(),
          resolved: false,
        });
      }
    },

    updateConversationByConversationEvent: (
      state,
      action: PayloadAction<Conversation>
    ) => {
      const index = state.data.findIndex(
        (item) =>
          item.did === action.payload.did &&
          item.participant === action.payload.participant
      );
      if (index !== -1) {
        state.data[index] = {
          ...state.data[index],
          ...action.payload,
          total_unread: Object.hasOwnProperty.call(action.payload, 'last_read_timestamp') ? 0 : state.data[index].total_unread,
          unread: Object.hasOwnProperty.call(action.payload, 'unread') ? action.payload.unread : state.data[index].unread,
          resolved: Object.hasOwnProperty.call(action.payload, 'resolved_at') ? action.payload.resolved_at !== null ? true : false : state.data[index].resolved
        };
      }
    },

    addConversationSlice: (state, action: PayloadAction<Conversation>) => {
      const index = state.data.findIndex(
        (item) =>
          item.did === action.payload.did &&
          item.participant === action.payload.participant
      )

      if (index === -1) {
        state.data.push(action.payload)
      }

    },

  },
});

export const {
  setConversations,
  setSelectedConversation,
  updateConversationByConversationEvent,
  updateConversationsByIncomingMessage,
  addConversationSlice
} = conversationSlice.actions;

export default conversationSlice.reducer;
